<template>
  <v-dialog :value="true" width="600" persistent>
    <v-card :loading="loading" :disabled="loading">
      <v-window v-model="step">
        <template v-for="(pendingFeedback, index) in pendingFeedbacks">
          <v-window-item :value="index" :key="pendingFeedback.id">
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <v-card>
                <app-page-header icon="mdi-alarm-check" :title="title" hide-close />
                <v-card-text class="">
                  <v-row>
                    <v-col cols="12">
                      <validation-provider :name="$t('feedback.feeling')" rules="required" v-slot="{ errors }">
                        <v-input v-model="feedbackForm.feeling" :error-messages="errors">
                          <v-row>
                            <v-col cols="12">
                              <span class="v-label theme--light">
                                {{ pendingFeedback.feedback.questionName }}
                              </span>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-space-around">
                              <div
                                v-for="feeling in feelings"
                                class="d-flex flex-column justify-center align-center px-1"
                                :key="feeling.value"
                                @click="feedbackForm.feeling = feeling.value"
                              >
                                <v-icon size="50" style="cursor: pointer;">
                                  {{ feedbackForm.feeling === feeling.value ? feeling.selected_icon : feeling.icon }}
                                </v-icon>
                                <span style="cursor: pointer;" class="text-center mt-2 font-weight-medium">{{
                                  $t(`feedback.feeling_enum.${feeling.value}`)
                                }}</span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-input>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <template v-if="pendingFeedback.feedback.answerType === FEEDBACK_ANSWER_TYPE.SINGLE_SELECTION">
                        <validation-provider :name="$t('feedback.reason')" rules="required" v-slot="{ errors }">
                          <v-input v-model="reason" :error-messages="errors">
                            <v-row>
                              <v-col cols="12">
                                <span class="v-label theme--light">{{ $t("feedback.reason") }}</span>
                              </v-col>
                              <v-col cols="12">
                                <v-radio-group v-model="reason" hide-details :mandatory="false" row class="ma-0">
                                  <v-radio
                                    v-for="(reason, index) in pendingFeedback.feedback.reasons"
                                    :key="index"
                                    :label="reason"
                                    :value="reason"
                                  />
                                </v-radio-group>
                              </v-col>
                            </v-row>
                          </v-input>
                        </validation-provider>
                      </template>
                      <template v-else>
                        <validation-provider :name="$t('feedback.reasons')" rules="required" v-slot="{ errors }">
                          <v-input v-model="feedbackForm.reasons" :error-messages="errors">
                            <v-row>
                              <v-col cols="12">
                                <span class="v-label theme--light font-weight-medium">{{
                                  $t("feedback.reasons")
                                }}</span>
                              </v-col>
                              <v-col cols="12" class="d-flex">
                                <v-checkbox
                                  hide-details
                                  class="mr-3 my-0"
                                  v-for="(reason, index) in pendingFeedback.feedback.reasons"
                                  :key="index"
                                  v-model="feedbackForm.reasons"
                                  :label="reason"
                                  :value="reason"
                                />
                              </v-col>
                            </v-row>
                          </v-input>
                        </validation-provider>
                      </template>
                    </v-col>
                    <v-col cols="12" v-if="pendingFeedback.feedback.commentEnabled">
                      <validation-provider :name="$t('feedback.comment')" rules="required|max:500" v-slot="{ errors }">
                        <v-textarea
                          v-model="feedbackForm.comment"
                          color="tertiary"
                          rows="2"
                          counter="500"
                          :label="$t('feedback.comment')"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <template v-if="!pendingFeedback.feedback.required">
                    <v-btn
                      elevation="0"
                      :disabled="loading"
                      v-if="!isInLastStep"
                      color="primary"
                      small
                      @click="nextStep"
                    >
                      {{ $t("buttons.skip") }}
                    </v-btn>
                    <v-btn
                      elevation="0"
                      v-if="isInLastStep"
                      :disabled="loading"
                      small
                      color="primary"
                      @click="$router.replace({ name: 'dashboard' })"
                    >
                      {{ $t("menu.dashboard") }}
                    </v-btn>
                  </template>
                  <v-spacer />
                  <v-btn
                    elevation="0"
                    small
                    :loading="loading"
                    color="primary"
                    @click="handleSubmit(() => validateAndSave(pendingFeedback))"
                  >
                    {{ $t("buttons.send") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </validation-observer>
          </v-window-item>
        </template>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<script>
  import { PENDING_FEEDBACKS } from "./query";
  import { FEEDBACK_ANSWER_TYPE, FEELING } from "@/helpers/enums";

  export default {
    name: "PendingFeedbacks",
    components: {
      AppPageHeader: () => import("@/layouts/app-page-header")
    },
    data: vm => ({
      loading: false,
      step: 0,
      pendingFeedbacks: [],
      FEEDBACK_ANSWER_TYPE,
      reason: null,
      requiredCount: 0,
      notRequiredCount: 0,
      feedbackForm: {
        id: null,
        reasons: [],
        feeling: null,
        comment: null
      },
      feelings: [
        {
          value: FEELING.BAD,
          icon: "fb-bad",
          selected_icon: "fb-bad-solid"
        },
        {
          value: FEELING.SAD,
          icon: "fb-sad",
          selected_icon: "fb-sad-solid"
        },
        {
          value: FEELING.NORMAL,
          icon: "fb-normal",
          selected_icon: "fb-normal-solid"
        },
        {
          value: FEELING.HAPPY,
          icon: "fb-happy",
          selected_icon: "fb-happy-solid"
        },
        {
          value: FEELING.ECSTATIC,
          icon: "fb-very-happy",
          selected_icon: "fb-very-happy-solid"
        }
      ]
    }),
    computed: {
      isInLastStep() {
        return this.step === this.pendingFeedbacks.length - 1;
      },
      title() {
        return this.$t("feedback.title", [this.step + 1, this.pendingFeedbacks.length]);
      }
    },
    watch: {
      reason(reason) {
        if (reason) {
          this.feedbackForm.reasons = [reason];
        }
      }
    },
    methods: {
      setPendingFeedbackCount(pendingFeedbacks) {
        this.requiredCount = pendingFeedbacks.filter(pendingFeedback => pendingFeedback.feedback.required).length;
        this.notRequiredCount = pendingFeedbacks.filter(pendingFeedback => !pendingFeedback.feedback.required).length;
        this.$store.commit("auth/SET_REQUIRED_PENDING_FEEDBACK_COUNT", this.requiredCount);
        this.$store.commit("auth/SET_NOT_REQUIRED_PENDING_FEEDBACK_COUNT", this.notRequiredCount);
      },
      validateAndSave(pendingFeedback) {
        this.feedbackForm.id = pendingFeedback.id;
        this.loading = true;
        this.$api.feedbackResultService
          .update(this.feedbackForm)
          .then(response => {
            if (response.status === 200) {
              if (pendingFeedback.feedback.required) {
                this.requiredCount -= 1;
                this.$store.commit("auth/SET_REQUIRED_PENDING_FEEDBACK_COUNT", this.requiredCount);
              } else {
                this.notRequiredCount -= 1;
                this.$store.commit("auth/SET_NOT_REQUIRED_PENDING_FEEDBACK_COUNT", this.notRequiredCount);
              }
              if (!this.isInLastStep) {
                this.nextStep();
              } else {
                this.$router.replace({ name: "dashboard" });
              }
            }
          })
          .catch(e => this.$helpers.showNotification(e.message))
          .finally(() => (this.loading = false));
      },
      resetFeedbackForm() {
        this.feedbackForm = {
          reasons: [],
          feeling: null,
          comment: null
        };
        this.reason = null;
      },
      nextStep() {
        this.step += 1;
        this.resetFeedbackForm();
      },
      fetchItems() {
        const options = {
          query: PENDING_FEEDBACKS,
          variables: {
            id: this.$store.state.auth.user.id
          },
          fetchPolicy: "no-cache"
        };
        this.loading = true;
        this.$apollo
          .query(options)
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              if (data.employee.pendingFeedbacks.length === 0) {
                this.$store.commit("auth/SET_REQUIRED_PENDING_FEEDBACK_COUNT", 0);
                this.$store.commit("auth/SET_NOT_REQUIRED_PENDING_FEEDBACK_COUNT", 0);
                return this.$router.replace({ name: "dashboard" });
              }
              this.pendingFeedbacks = data.employee.pendingFeedbacks.sort(
                (a, b) => Number(b.feedback.required) - Number(a.feedback.required)
              );
              this.setPendingFeedbackCount(data.employee.pendingFeedbacks);
            }
          })
          .catch(e => this.$helpers.showNotification(e.toString()))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style></style>
