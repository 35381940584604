import gql from "graphql-tag";

export const PENDING_FEEDBACKS = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      pendingFeedbacks {
        id
        feedback {
          id
          questionName
          answerType
          color
          title
          reasons
          commentEnabled
          required
          anonymous
          results {
            done
            undone
            publishedDateTime
          }
        }
        feeling
        reasons
        employee {
          id
          firstName
          lastName
        }
        comment
        done
        completedDateTime
        feelingCounts
      }
    }
  }
`;
